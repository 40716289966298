
@use 'sass:color';

.register-page {
    width: 100vw;

    .navbar-brand, .navbar-aside-toggler {
        display: none;
    }

    .navbar-collapse {
        display: block !important;
    }

    .navbar-nav {
        justify-content: space-around !important;
        padding: 0 !important;
        flex-direction: row;
    }
}

.navbar-brand-logo-mini{
  width: 100% !important;
}

.pac-container {
  z-index: 9999999;
}

.chat-conversation, .chat-list {
  height: calc(100vh - 375px);
  overflow-y: scroll;
}

.page-list {
  .page-list-entry > div {
    padding: 5px 0;
    border: 1px solid #CCC;
    margin: 5px 0;
  }

  i {
    cursor: pointer;
  }

  .active {
    color: red;
  }
}


.header-row .modal-title {
  display: block;
  width: 100%;
}

.comma > span:not(:last-child)::after {
  content: ', ';
}

.table th {
  vertical-align: middle;
}

.chat-active {
  color: #495057;
  background-color: #e9ecef;
}


.chat-online {
  color: #34ce57
}

.chat-offline {
  color: #e4606d
}

.chat-messages {
  display: flex;
  flex-direction: column-reverse !important;
  overflow-y: scroll;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.chat-message-left, .chat-message-right {
  display: flex;
  flex-shrink: 0
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.flex-full {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
}

.container-fluid.p-0 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.no-gutters.row {
  flex: 1;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.min-wrap{
  width: 1%;
}


.chat-user-box p.user-title {
    color: #343a40;
    font-weight: 600
}

.chat-user-box p {
    font-size: 12px
}

@media (max-width: 767px) {
    .email-leftbar {
        float:none;
        width: 100%
    }

    .email-rightbar {
        margin: 0
    }
}

.mail-list a {
    display: block;
    color: #74788d;
    line-height: 24px;
    padding: 8px 5px
}

.mail-list a.active {
    color: #ff3d60;
    font-weight: 500
}

.message-list {
    display: block;
    padding-left: 0
}

.message-list li {
    position: relative;
    display: block;
    height: 50px;
    line-height: 50px;
    cursor: default;
    -webkit-transition-duration: .3s;
    transition-duration: .3s
}

.message-list li a {
    color: #74788d
}

.message-list li:hover {
    background: #f1f5f7;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms
}

.message-list li .col-mail {
    float: left;
    position: relative
}

.message-list li .col-mail-1 {
    width: 320px
}

.message-list li .col-mail-1 .checkbox-wrapper-mail,.message-list li .col-mail-1 .dot,.message-list li .col-mail-1 .star-toggle {
    display: block;
    float: left
}

.message-list li .col-mail-1 .dot {
    border: 4px solid transparent;
    border-radius: 100px;
    margin: 22px 26px 0;
    height: 0;
    width: 0;
    line-height: 0;
    font-size: 0
}

.message-list li .col-mail-1 .checkbox-wrapper-mail {
    margin: 15px 10px 0 20px
}

.message-list li .col-mail-1 .star-toggle {
    margin-top: 18px;
    margin-left: 5px
}

.message-list li .col-mail-1 .title {
    position: absolute;
    top: 0;
    left: 110px;
    right: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0
}

.message-list li .col-mail-2 {
    position: absolute;
    top: 0;
    left: 320px;
    right: 0;
    bottom: 0
}

.message-list li .col-mail-2 .date,.message-list li .col-mail-2 .subject {
    position: absolute;
    top: 0
}

.message-list li .col-mail-2 .subject {
    left: 0;
    right: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap
}

.message-list li .col-mail-2 .date {
    right: 0;
    width: 170px;
    padding-left: 80px
}

.message-list li.active,.message-list li.active:hover {
    -webkit-box-shadow: inset 3px 0 0 #5664d2;
    box-shadow: inset 3px 0 0 #5664d2
}

.message-list li.unread {
    background-color: #f1f5f7;
    font-weight: 500;
    color: #292d32
}

.message-list li.unread a {
    color: #292d32;
    font-weight: 500
}

.message-list .checkbox-wrapper-mail {
    cursor: pointer;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    -webkit-box-shadow: inset 0 0 0 1px #ced4da;
    box-shadow: inset 0 0 0 1px #ced4da;
    border-radius: 1px
}

.message-list .checkbox-wrapper-mail input {
    opacity: 0;
    cursor: pointer
}

.message-list .checkbox-wrapper-mail input:checked~label {
    opacity: 1
}

.message-list .checkbox-wrapper-mail label {
    position: absolute;
    height: 20px;
    width: 20px;
    left: 0;
    cursor: pointer;
    opacity: 0;
    margin-bottom: 0;
    -webkit-transition-duration: 50ms;
    transition-duration: 50ms;
    top: 0
}

.message-list .checkbox-wrapper-mail label:before {
    content: "\f012c";
    font-family: "Material Design Icons";
    top: 0;
    height: 20px;
    color: #292d32;
    width: 20px;
    position: absolute;
    margin-top: -16px;
    left: 4px;
    font-size: 13px
}

@media (max-width: 575.98px) {
    .message-list li .col-mail-1 {
        width:200px
    }
}

.chat-leftsidebar {
    background-color: #fff;
    border-radius: .25rem 0 0 .25rem;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08);
    box-shadow: 0 2px 4px rgba(0,0,0,.08)
}

@media (min-width: 992px) {
    .chat-leftsidebar {
        min-width:360px
    }
}

.chat-leftsidebar .chat-leftsidebar-nav .nav {
    background-color: #f1f5f7
}

.chat-leftsidebar .chat-leftsidebar-nav .nav .nav-link.active {
    background-color: #fff;
    color: #5664d2
}

.chat-noti-dropdown.active:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #ff3d60;
    border-radius: 50%;
    right: 0
}

.chat-noti-dropdown .btn {
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 16px
}

.chat-search-box .form-control {
    border: 0
}

.chat-list {
    margin: 0
}

.chat-list li.active a {
    background-color: rgba(241,245,247,.7)
}

.chat-list li a {
    display: block;
    padding: 14px 16px;
    color: #74788d;
    -webkit-transition: all .4s;
    transition: all .4s;
    border-top: 1px solid #eff2f7;
    border-radius: 4px
}

.chat-list li a:hover {
    background-color: rgba(241,245,247,.7)
}

.chat-list li .user-img {
    position: relative
}

.chat-list li .user-img .user-status {
    width: 10px;
    height: 10px;
    background-color: #adb5bd;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    right: 0;
    bottom: 0
}

.chat-list li .user-img.online .user-status {
    background-color: #1cbb8c
}

.chat-list li .user-img.away .user-status {
    background-color: #fcb92c
}

.user-chat {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08);
    box-shadow: 0 2px 4px rgba(0,0,0,.08)
}

.user-chat .user-chat-border {
    border-bottom: 1px solid #eff2f7
}

.user-chat-nav .dropdown .nav-btn {
    height: 36px;
    width: 36px;
    line-height: 36px;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    font-size: 20px;
    border-radius: 50%
}

.user-chat-nav .dropdown .dropdown-menu {
    -webkit-box-shadow: 0 2px 4px rgba(0,0,0,.08);
    box-shadow: 0 2px 4px rgba(0,0,0,.08);
    border: 1px solid #eff2f7
}

.chat-conversation li {
    clear: both
}

.chat-conversation .chat-avatar {
    float: left;
    margin-right: 8px
}

.chat-conversation .chat-avatar img {
    width: 36px;
    height: 36px;
    border-radius: 50%
}

.chat-conversation .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 12px
}

.chat-conversation .chat-day-title .title {
    background-color: #eff2f7;
    position: relative;
    z-index: 1;
    padding: 3px 16px;
    border-radius: 30px
}

.chat-conversation .chat-day-title:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: #eff2f7;
    top: 10px
}

.chat-conversation .chat-day-title .badge {
    font-size: 12px
}

.chat-conversation .conversation-list {
    margin-bottom: 24px;
    display: inline-block;
    position: relative
}

.chat-conversation .conversation-list .ctext-wrap {
    overflow: hidden
}

.chat-conversation .conversation-list .ctext-wrap .conversation-name {
    font-weight: 600;
    margin-bottom: 7px
}

.chat-conversation .conversation-list .ctext-wrap-content {
    padding: 12px 16px;
    background-color: #5664d2;
    border-radius: .25rem;
    color: #fff
}

.chat-conversation .conversation-list .chat-time {
    margin-top: 7px;
    font-size: 12px;
    text-align: right
}

.chat-conversation .right .conversation-list {
    float: right
}

.chat-conversation .right .conversation-list .conversation-name {
    text-align: right
}

.chat-conversation .right .conversation-list .ctext-wrap-content {
    background-color: #eff2f7;
    text-align: right;
    color: #74788d
}

.chat-conversation .right .conversation-list .chat-time {
    text-align: left
}

.chat-input-section {
    background-color: #fff;
    border-radius: .25rem
}

.chat-input {
    background-color: #eff2f7!important;
    border-color: #eff2f7!important
}

.chat-input-links {
    position: absolute;
    left: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
}

.chat-input-links li a {
    font-size: 16px;
    line-height: 36px;
    padding: 0 4px;
    display: inline-block
}

@media (max-width: 575.98px) {
    .chat-send {
        min-width:auto
    }
}


$color-empty-question: #f8ffff;
$color-not-empty-question: #1bc4bc;

.question {
  > div {
    place-items: center;
  }

margin: 10px 0;
  padding: 5px 15px;
  border: 3px solid;
  border-radius: 10px;

  &.empty {
    background: lighten($color-empty-question, 25%);
    border-color: $color-empty-question;
  }

&.not-empty {
    background: lighten($color-not-empty-question, 40%);
    border-color: $color-not-empty-question;
  }

}

$color-active: #3B85C3;
$color-disabled: #C0C0C0;

ul.modules {
  margin: 15px 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-around;

  > li {
    &:first-child, &:last-child {
      flex-grow: unset;
    }

  flex-grow: 1;
    text-align: center;

    &:first-child {
      > span::after {
        left: 50%;
      }

    &.active {
        > span::after {
          background: $color-disabled;
        }
      }
    }

  &:last-child {
      > span::after {
        right: 50%;
      }

  &.active {
        > span::after {
          background: $color-active;
        }
      }
    }

  &.past {
      color: #000;

      > span {
        &::before {
        }
      }
    }

  &:not(.past) {
      color: #C0C0C0;
    }

  &.active {
      > span::before {
        background: #3B85C3;
      }

  > span::after {
        background: linear-gradient(90deg,$color-active 50%, $color-disabled 0);
      }
    }

  &.future {
      > span::before {
        background: #E1DDD9;
        border-color:  #E1DDD9;
      }
    }

  &.past {
      > span::before {
        background: #FFF url("https://icons.getbootstrap.com/assets/icons/check2.svg") no-repeat center;
        font-weight: bold;
      }

  > span::after {
        background: $color-active;
      }
    }

  > span {
      display: block;
      position: relative;

      &::before {
        position: relative;
        display: block;
        content: ' ';
        height: 32px;
        width: 32px;
        margin: 0 auto;
        border: solid 4px #3B85C3;
        border-radius: 50%;
        z-index: 2;
      }

  &::after {
        content: ' ';
        display: block;
        width: 100%;
        height: 4px;
        top: 14px;
        z-index: 1;
        position: absolute;
        background: #C0C0C0;
      }
    }
  }
}
